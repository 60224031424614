import React from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import Button from './Button'

const Detail = ({selectedProject, onClick}) => {

		return (

			<TransitionGroup>

				<CSSTransition
						key={selectedProject.id}
						timeout={800}
						classNames="move"
						unmountOnExit
					>
					<div className="detail-container">

						<div className="close-project">
							<i className="fas fa-chevron-right fa-lg" onClick={() => onClick()}></i>
						</div>

						<div className="detail-text-container">
							<h1>{selectedProject.title}</h1>

							<p className="subtitle">{selectedProject.subtitle}</p>

							{selectedProject.image ?  <img src={selectedProject.image} alt="img"/> : null}

							{selectedProject.textOne && <p>{selectedProject.textOne}</p> }
							{selectedProject.textTwo && <p>{selectedProject.textTwo}</p> }
							{selectedProject.textThree && <p>{selectedProject.textThree}</p> }

							<Button
								link={selectedProject.github}
								icon={"fab fa-github fa-2x"}
								label="Github"
							/>

							<Button
								link={selectedProject.live}
								icon={"fas fa-play-circle fa-2x"}
								label="Live site"
							/>

							<div className="devicon-container">
								{selectedProject.tech && selectedProject.tech.Nodejs &&  <i className="devicon devicon-nodejs-plain"></i>}
								{selectedProject.tech && selectedProject.tech.Express &&  <i className="devicon devicon-express-original"></i>}
								{selectedProject.tech && selectedProject.tech.Django &&  <i className="devicon devicon-django-plain"></i>}
								{selectedProject.tech && selectedProject.tech.JavaScript &&  <i className="devicon devicon-javascript-plain"></i>}
								{selectedProject.tech && selectedProject.tech.React &&  <i className="devicon devicon-react-original-wordmark"></i>}
								{selectedProject.tech && selectedProject.tech.mongoDB &&  <i className="devicon devicon-mongodb-plain-wordmark"></i>}
							</div>

						</div>

					</div>
				</CSSTransition>

			</TransitionGroup>
		)


}

export default Detail
