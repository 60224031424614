import React from 'react'

const Button = ({link, icon, label}) => {

	if (link) {
		return (
			<a href={link} target="_blank" rel="noopener noreferrer" className="button">
				<i className={icon}></i><span>{label}</span>
			</a>
		)
	}
	else {
		return (null)
	}

}

export default Button
