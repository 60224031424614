import React, { Component } from 'react'
import Radium from 'radium'

class Project extends Component {

	constructor(props) {
		super(props)
		this.state = {
			project: {},
			isSelected: false,
			isUnselected: false
		}
	}

	componentWillMount() {
		this.setState({
			project: this.props.project
		})
	}

	componentDidUpdate(prevProps) {
		if (this.props.selectedProject !== prevProps.selectedProject) {
			if (this.props.selectedProject.id === 0)
			{
				this.setState({
					isSelected: false,
					isUnselected: false
				})
			}
			else if (this.props.selectedProject === this.state.project)
			{
				this.setState({
					isSelected: true,
					isUnselected: false
				})
			}
			else
			{
				this.setState({
					isSelected: false,
					isUnselected: true
				})
			}
		}

	}

	render() {

		let styles = {
			container: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			width: '280px',
			height: '215px',
			backgroundColor: '#fff',
			padding: '8px 8px 0 8px',
			margin: '12px',
			borderRadius: '3px',
			boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.2)',
			cursor: 'pointer',
			transition: 'all .3s',
			':hover': {
					opacity: '1',
					boxShadow: '0px 1px 8px 3px rgba(81, 172, 232, 0.7)'
				}
			},
			isSelected: {
				opacity: '1',
				boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.4)'
			},
			isUnselected: {
				opacity: '0.4'
			},
			img: {
				objectFit: 'cover',
				width: '100%',
				height: '170px',
				borderRadius: '3px',
				textAlign: 'center',
				lineHeight: '170px'
			},
			title: {
				margin: 'auto'
			}
		}

		return (
			<div
				className="Project"
				style={
					Object.assign({},
					styles.container,
					this.state.isSelected && styles.isSelected,
					this.state.isUnselected && styles.isUnselected
				)}
				onClick={() => this.props.onClick(this.props.project)}
			>
				<img src={this.state.project.image} style={styles.img} alt="not found"/>
				<h4 style={styles.title}>
					{this.state.project.shortTitle
						? this.state.project.shortTitle
						: this.state.project.title}
				</h4>
			</div>
		)
	}

}

export default Radium(Project)
