import React, { Component } from 'react'

class Particles extends Component {

	constructor(props) {
		super(props)
		this.state = {
			particles: {},
			particleIndex: 0,
			settings: {
				density: 150,
				maxSpeed: 0.5,
				minSpeed: 0.05,
				startingX: window.innerWidth / 2,
				startingY: window.innerHeight / 2
			}
		}
	}

	componentDidMount() {
		this.setState({
			canvas: this.refs.canvas,
			ctx: this.refs.canvas.getContext("2d")
		}, () => this.setupCanvas())
	}

	setupCanvas = () => {
		this.setBackground()
		this.generateParticles()
		this.startAnimation()
	}

	startAnimation = () => {
		let _this = this
		setInterval(function() {
			_this.setBackground()
			for (let i in _this.state.particles) {
				_this.state.particles[i].draw(
					_this.state.settings,
					_this.state.canvas,
					_this.state.ctx
				)
			}
		}, 30);
	}

	setBackground = () => {
		let canvas = this.state.canvas
		let ctx = this.state.ctx

		canvas.style.width = "100%"
		canvas.style.height = "100%"
		canvas.width  = canvas.offsetWidth;
		canvas.height = canvas.offsetHeight;

		// TODO choose colors
		let gradient = ctx.createLinearGradient(0, 0, canvas.height/1.4, canvas.width/1.4)
		gradient.addColorStop(1, "#FEAC5E")
		gradient.addColorStop(0.5, "#C779D0")
		gradient.addColorStop(0, "#4BC0C8")
		ctx.fillStyle = gradient
		ctx.fillRect(0, 0, canvas.width, canvas.height)
	}

	generateParticles = () => {
		let particles = {}
		for (let i = 0; i < this.state.settings.density; i++) {
			particles[i] = new SingleParticle(i, this.state.settings, this.state.canvas)
		}
		this.setState({
			particles: particles
		})
	}

	render() {

		return (
			<canvas
				ref="canvas"
				style={this.props.style}
				className={this.props.className}
				id = {this.props.id}
			>
			</canvas>
		)
	}

}

export default Particles


class SingleParticle {

	constructor(particleIndex, settings, canvas) {

		this.x = settings.startingX;
		this.y = settings.startingY;

		this.x = this.getRandomInRange(0, canvas.width);
		this.y = this.getRandomInRange(0, canvas.height);

		this.vx = 0;
		this.vy = 0;
		// TODO choose direction and speed
		while ((this.vx < settings.minSpeed && this.vx > -settings.minSpeed) || (this.vy < settings.minSpeed && this.vy > -settings.minSpeed)) {
			this.vx = this.getRandomInRange((-settings.maxSpeed), settings.maxSpeed);
			this.vy = this.getRandomInRange((-settings.maxSpeed), settings.maxSpeed);
		}

		this.opacity = this.getRandomInRange(0, 0.8);
		this.lastOpacity = this.opacity + this.getRandomInRange(-1, 1);

		// TODO choose speed
		this.particleSize = this.getRandomInRange(0.5, 3.5);

		this.id = particleIndex;

		return this
	}

	draw = (settings, canvas, ctx) => {
		this.x += this.vx;
		this.y += this.vy;

		// TODO choose bounce/pass through
		if (this.x < 0) {
			this.x = ctx.canvas.width;
		}
		if (this.x > ctx.canvas.width) {
			this.x = 0;
		}
		if (this.y < 0) {
			this.y = ctx.canvas.height;
		}
		if (this.y > ctx.canvas.height) {
			this.y = 0;
		}

		// TODO choose opacity/fade effect
		if (this.opacity > this.lastOpacity) {
			this.lastOpacity = this.opacity;
			this.opacity += 0.01;
			if (this.opacity > 0.8) {
				this.lastOpacity = 10;
			}
		} else {
			this.lastOpacity = this.opacity;
			this.opacity -= 0.01;
			if (this.opacity < 0) {
				this.lastOpacity = -10;
			}
		}

		ctx.clearRect(settings.leftWall, settings.groundLevel, canvas.width, canvas.height);
		ctx.beginPath();
		ctx.fillStyle = "rgba(255, 255, 255, " + this.opacity + ")";
		ctx.shadowColor = "black";
		ctx.shadowOffsetX = 0;
		ctx.shadowOffsetY = 0;
		ctx.arc(this.x, this.y, this.particleSize, 0, Math.PI*2, true);
		ctx.closePath();
		ctx.fill();
	}

	getRandomInRange = (min, max) => {
		return (Math.random() * (max - min) + min);
	}

}
