import React, { Component } from 'react'
import Radium from 'radium'
import { CSSTransition } from 'react-transition-group'
import ProjectData from './projects.json'
import Particles from './Particles'
import Project from './Project'
import Detail from './Detail'

class App extends Component {

	constructor(props) {
		super(props)
		this.state = {
			projects: [],
			selectedProject: {
				id: 0
			},
			isSelected: false
		}
	}

	loadProjects = () => {
		this.setState({
			projects: ProjectData
		})
	}

	setSelectedProject = proj => {
		this.setState({
			isSelected: true,
			selectedProject: proj
		})
		setTimeout(() => {
			window.scrollTo({
				top: 0,
				behavior: 'smooth'
			})
		}, 400)
	}

	deselectProjects = () => {
		this.setState({
			isSelected: false,
			selectedProject: {
				id: 0
			}
		})
	}

	scrollToProjects = () => {
		window.scrollTo({
			top: window.innerHeight+1,
			behavior: 'smooth'
		})
	}

	componentWillMount() {
		this.loadProjects()
	}

	render() {

		const projects = this.state.projects.map((proj) =>
			<Project
				key={proj.id}
				project={proj}
				selectedProject={this.state.selectedProject}
				onClick={this.setSelectedProject}
			/>
		)

		const header = (

			<div className="my-profile">
				<h1>Michael Priest</h1>
				<h2>Software Developer, UK</h2>
				<div className="links">
					<a href="mailto:me@michaelpriest.dev"><p className="link"><i className="fas fa-envelope"></i> me@michaelpriest.dev</p></a>
					<a href="https://github.com/mcbp"><p className="link"><i className="fab fa-github"></i> mcbp</p></a>
					<a href="https://www.linkedin.com/in/michael-priest/"><p className="link"><i className="fab fa-linkedin-in"></i> MPriest</p></a>
				</div>
				<div className="scroll-down" onClick={this.scrollToProjects}>
					<i className="fas fa-chevron-down fa-3x"></i>
				</div>
			</div>

		)

		const projectDetails = (
				<Detail
					selectedProject={this.state.selectedProject}
					onClick={this.deselectProjects}
				/>
		)

		let styles = {
			dark: {
				backgroundColor: '#e6e6e6'
			},
			canvas: {
				position: "absolute",
			}
		}

		return (
			<div className="App">

				<div className="mobile-background"></div>

				<Particles className="particles" style={styles.canvas}/>

				<div className="col-1">

					{/*<Particles style={styles.canvas}/>*/}

					<div className="canvas-overlay">

							<CSSTransition
								in={!this.state.isSelected}
								timeout={800}
								classNames="fade"
								unmountOnExit
							>
								{header}
							</CSSTransition>

							<CSSTransition
								in={this.state.isSelected}
								timeout={800}
								classNames="move"
								unmountOnExit
							>
								{projectDetails}
							</CSSTransition>

					</div>

				</div>

				<div className="col-2" >

					<div className="project-header-container">
						<h3>My Projects</h3>
					</div>

					<div className="projects-container">

						{projects}

					</div>

				</div>

			</div>
		)
	}
}

export default Radium(App)
